import { GridLayout } from "@progress/kendo-react-layout";
import cls from "classnames";
import type { ComponentProps } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorCode } from "../../../../../common/models/src/lib/enums/error-codes.enum";
import { useAppSelector } from "../../../../../common/stores/src/lib/utils";
import { CelerumButton } from "../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumCard } from "../../../../../common/ui/src/lib/components/celerum-cards/celerum-cards.component";
import { ResetPasswordForm } from "../reset-password/components/reset-password-form";
import styles from "./account-confirmation.module.css";

interface AccountConfirmationProps {
	onSubmit: (password: string) => void;
	resendEmail: () => void;
}
type ResetPasswordFormProps = ComponentProps<typeof ResetPasswordForm>;
export const AccountConfirmation = ({
	onSubmit,
	resendEmail,
}: AccountConfirmationProps) => {
	const { errorCode } = useAppSelector((state) => state.authentication);

	const validToken = errorCode === ErrorCode.INVALID_ACTIVATION_ACCOUNT_TOKEN;

	const handleSubmit: ResetPasswordFormProps["handleSubmit"] = (dataItem) => {
		onSubmit(dataItem.password);
	};

	const navigate = useNavigate();

	const renderDescription = () => {
		return validToken ? (
			<h3 className={styles.description}>Your token has expired!</h3>
		) : (
			<h3 className={styles.description}>
				To protect your account, make sure you choose a strong password.
			</h3>
		);
	};

	return (
		<GridLayout
			align={{
				horizontal: "center",
			}}
		>
			<CelerumCard
				className={cls(
					styles.card,
					validToken ? styles.card_small : styles.card__default,
				)}
			>
				<div className={styles.titleContainer}>
					<h1 className={styles.title}>Account confirmation</h1>
					{renderDescription()}
				</div>
				{validToken ? (
					<CelerumButton
						title="Resend email!"
						type="button"
						onClick={() => {
							resendEmail?.();
							navigate("/login");
						}}
					/>
				) : (
					<ResetPasswordForm styles={styles} handleSubmit={handleSubmit} />
				)}
			</CelerumCard>
		</GridLayout>
	);
};
