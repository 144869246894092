import { GridLayout } from "@progress/kendo-react-layout";
import cls from "classnames";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../common/stores/src/lib/utils";
import { CelerumButton } from "../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumCard } from "../../../../../common/ui/src/lib/components/celerum-cards/celerum-cards.component";
import { ForgotPasswordForm } from "./forgot-password-form/forgot-password-form.component";
import styles from "./forgot-password.module.css";

export const ForgotPassword = () => {
	const navigate = useNavigate();

	const { isMobile } = useAppSelector((state) => state.userInterface);

	const [isEmailLinkSent, setIsEmailLinkSent] = useState<boolean>(false);

	const renderDescription = () => {
		return isEmailLinkSent ? (
			<h3 className={styles.description}>
				If you are configured in the system, an email has been sent with a link
				to reset your password. Please click the link when you get it.
			</h3>
		) : (
			<h3 className={styles.description}>Please add your email below.</h3>
		);
	};

	const renderBody = () => {
		return (
			<>
				<div className={styles.titleContainer}>
					<h1 className={styles.title}>Forgotten Password</h1>
					{renderDescription()}
				</div>
				{isEmailLinkSent ? (
					<CelerumButton
						style={{ width: "100%" }}
						title="Go to log in"
						type="button"
						onClick={() => navigate("/login")}
					/>
				) : (
					<ForgotPasswordForm setIsEmailLinkSent={setIsEmailLinkSent} />
				)}
			</>
		);
	};

	return isMobile ? (
		<div className={styles.mobile}>{renderBody()}</div>
	) : (
		<GridLayout align={{ horizontal: "center" }}>
			<CelerumCard
				className={cls(
					styles.card,
					isEmailLinkSent ? styles.card__small : styles.card__default,
				)}
			>
				{renderBody()}
			</CelerumCard>
		</GridLayout>
	);
};
