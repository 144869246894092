import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTitle } from "react-use";
import { resetPasswordAction } from "../../../libs/authentication/data-access/src/lib/authentication.slice";
import { ResetPassword } from "../../../libs/authentication/feature/src/lib/reset-password/reset-password.component";
import { useAppDispatchWithNotifications } from "../../../libs/common/stores/src/lib/utils";
import styles from "./reset-password.module.css";

export const ResetPasswordPage = () => {
	useTitle("Reset Password");
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	/** The split and join modification is necessary because the .NET backend generates the reset password token with +-s.
	 *  When we access the URL parameters however, +-s are treated as spaces but this alters the original token.
	 */
	const [searchParams] = useSearchParams();
	const token = searchParams.get("token")?.split(" ").join("+");
	const email = searchParams.get("email");

	const [isPasswordReset, setIsPasswordReset] = useState(false);

	const handleResetPassword = async (password: string) => {
		const result = await dispatchWithNotifications({
			action: resetPasswordAction,
			payload: {
				password,
				token,
				email,
			},
			errorMessage: "Could not reset password.",
		});

		if (resetPasswordAction.fulfilled.match(result)) {
			setIsPasswordReset(true);
		}
	};

	return (
		<div className={styles.root}>
			<div className={styles.leftSide} />
			<div className={styles.rightSide}>
				<ResetPassword
					isPasswordReset={isPasswordReset}
					onSubmit={handleResetPassword}
				/>
			</div>
		</div>
	);
};
