import { useGoogleMap } from "@react-google-maps/api";
import {
	type Dispatch,
	type SetStateAction,
	useCallback,
	useEffect,
	useRef,
} from "react";
import type { ILocationRequestDto } from "../../../../../../common/models/src/lib/interfaces/location.interface";
import styles from "./google-maps-searchbox.module.css";

interface GoogleMapsSearchBoxProps {
	setAddress: (address: string) => void;
	setFormState: Dispatch<SetStateAction<ILocationRequestDto>>;
	markers: google.maps.Marker[];
	setMarkers: Dispatch<SetStateAction<google.maps.Marker[]>>;
}

export const GoogleMapsSearchBox = ({
	markers,
	setMarkers,
	setAddress,
	setFormState,
}: GoogleMapsSearchBoxProps) => {
	const inputRef = useRef<HTMLInputElement | null>(null);
	const searchBox = useRef<google.maps.places.SearchBox | null>(null);

	const map = useGoogleMap();

	const handleOnPlacesChanged = useCallback(() => {
		if (!(searchBox.current && map)) {
			return;
		}
		const places = searchBox.current.getPlaces();
		if (!places) return;
		const firstPlace = places[0];
		if (!firstPlace) return;

		setAddress(firstPlace.formatted_address as string);
		setFormState((prevState) => ({
			...prevState,
			address: firstPlace.formatted_address as string,
			latitude: firstPlace.geometry?.location?.lat(),
			longitude: firstPlace.geometry?.location?.lng(),
		}));

		// Clear out the old markers.
		for (const marker of markers) {
			marker.setMap(null);
		}

		const newMarkers: google.maps.Marker[] = [];

		// For each place, get the icon, name and location.
		const bounds = new google.maps.LatLngBounds();

		for (const place of places) {
			if (!place.geometry || !place.geometry.location) continue;

			const icon = {
				url: place.icon as string,
				size: new google.maps.Size(71, 71),
				origin: new google.maps.Point(0, 0),
				anchor: new google.maps.Point(17, 34),
				scaledSize: new google.maps.Size(25, 25),
			};

			newMarkers.push(
				new google.maps.Marker({
					map,
					icon,
					title: place.name,
					position: place.geometry.location,
				}),
			);

			if (place.geometry.viewport) {
				// Only geocodes have viewport.
				bounds.union(place.geometry.viewport);
			} else {
				bounds.extend(place.geometry.location);
			}
		}

		map.fitBounds(bounds);
		setMarkers(newMarkers);
	}, [map, markers, setAddress, setFormState, setMarkers]);

	useEffect(() => {
		if (!searchBox.current && map && inputRef.current) {
			searchBox.current = new google.maps.places.SearchBox(inputRef.current);
			searchBox.current.addListener("places_changed", handleOnPlacesChanged);
		}

		return () => {
			if (map) {
				searchBox.current = null;
				google.maps.event.clearListeners(map, "places_changed");
			}
		};
	}, [map, handleOnPlacesChanged]);

	return (
		<div>
			<input
				ref={inputRef}
				type="text"
				className={styles.textBox}
				placeholder="Search..."
			/>
		</div>
	);
};
