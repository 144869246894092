import {
	DropDownButton,
	DropDownButtonItem,
} from "@progress/kendo-react-buttons";
import { SvgIcon } from "@progress/kendo-react-common";
import { Card } from "@progress/kendo-react-layout";
import {
	chevronLeftIcon,
	equalIcon,
	pauseIcon,
	printIcon,
	trashIcon,
	zoomInIcon,
} from "@progress/kendo-svg-icons";
import dayjs from "dayjs";
import { useMemo } from "react";
import type { LoadDetailsResponse } from "../../api/JobApi";
import { DFlex } from "../../display/DFlex";
import { JobStatusTag } from "../../helpersReact";

const useActions = (actions: LoadDetailsResponse["actions"]) =>
	useMemo(
		() =>
			[
				{ text: "Pause", icon: pauseIcon, disabled: !actions.allowPause },
				{ text: "Audit", icon: zoomInIcon, disabled: !actions.allowAudit },
				{
					text: "Delete",
					icon: trashIcon,
					disabled: !actions.allowCancel,
				},
				{
					text: "Multi Edit",
					icon: equalIcon,
					disabled: !actions.allowEdit,
				},
				// TODO: Why is there multiple way to generate documents - bundled/convoluted?
				{
					text: "Generate Manifest",
					icon: printIcon,
					disabled: !actions.allowGenerateManifest,
				},
				{
					text: "Generate Manifest and CMR",
					icon: printIcon,
					disabled: !actions.allowGenerateManifestAndCmr,
				},
				{
					text: "Generate Manifest and Delivery Tickets",
					icon: printIcon,
					disabled: !actions.allowGenerateManifestAndDeliveryTickets,
				},
				{
					text: "Generate Clear Customs",
					icon: printIcon,
					disabled: !actions.allowViewClearCustomsDoc,
				},
				{
					text: "Generate All Documents",
					icon: printIcon,
					disabled: !actions.allowGenerateAllDocuments,
				},
			] as const,
		[actions],
	);
type PossibleAction = ReturnType<typeof useActions>[number]["text"];
type LoadDetailsTitleProps = {
	data: LoadDetailsResponse;
	onGoBackClick: () => void;
	onActionClick: (action: PossibleAction) => void;
};
export const LoadDetailsTitle = ({
	data,
	onGoBackClick,
	onActionClick,
}: LoadDetailsTitleProps) => {
	const actions = useActions(data.actions);
	return (
		<Card>
			<DFlex center fullHeight spaceBetween margin="0 16px">
				<DFlex center>
					<SvgIcon
						icon={chevronLeftIcon}
						onClick={onGoBackClick}
						style={{ cursor: "pointer" }}
					/>
					<div>
						<h3>{data.uniqueId}</h3>
						<small>
							Created at{" "}
							{dayjs(data.creationTime).format("HH:mm:ss, DD/MM/YYYY")} by{" "}
							{data.creationByUserName}
						</small>
					</div>
					<JobStatusTag status={data.status} />
				</DFlex>
				<DropDownButton
					text="Actions"
					fillMode="outline"
					icon="arrow-chevron-down"
					onItemClick={(e) => onActionClick(e.item.text)}
				>
					{actions.map((action) => (
						<DropDownButtonItem
							key={action.text}
							text={action.text}
							svgIcon={action.icon}
							disabled={action.disabled}
						/>
					))}
				</DropDownButton>
			</DFlex>
		</Card>
	);
};
