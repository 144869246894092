import { atom, createStore } from "jotai";
import SuperJSON from "superjson";
import type {
	AuthenticationResponse,
	UserLoginRequest,
	ViewBusinessUnitResponse,
} from "./api/AuthenticationApi";
import { authenticationApi } from "./helpers";

type Auth = {
	businessUnit?: ViewBusinessUnitResponse;
	user?: AuthenticationResponse;
};
const _authAtom = atom<string | null>(localStorage.getItem("auth"));
export const authAtom = atom(
	(get) => {
		const value = get(_authAtom);
		if (!value) return null;
		return SuperJSON.parse<Auth>(value);
	},
	(_get, set, value: Auth | null) => {
		if (!value) {
			localStorage.removeItem("auth");
			return set(_authAtom, null);
		}
		const str = SuperJSON.stringify(value);
		localStorage.setItem("auth", str);
		set(_authAtom, str);
	},
);
export const store = createStore();

export const authStoreLogin = async (params: UserLoginRequest) => {
	const res =
		await authenticationApi.authentication.authenticationCreate(params);
	store.set(authAtom, { user: res.data });
	return res;
};

export const authStoreSelectBusinessUnit = async (id: string) => {
	const res = await authenticationApi.businessUnit.businessUnitDetail(id);
	const value = store.get(authAtom);
	store.set(authAtom, { ...value, businessUnit: res.data });
	return res;
};
