import { CelerumSkeleton } from "../../../celerum-skeleton/celerum-skeleton.component";
import { CelerumStatus } from "../../../celerum-status/celerum-status.component";
import styles from "./status.cell.module.css";

interface StatusCellProps {
	loading: boolean;
	status?: {
		title: string;
		className: string;
	};
}

export const StatusCell = ({ loading, status }: StatusCellProps) => (
	<td>
		<div className={styles.container}>
			{loading ? (
				<CelerumSkeleton />
			) : (
				<CelerumStatus
					title={status?.title ?? "New"}
					className={status?.className ? styles[status.className] : styles.gray}
				/>
			)}
		</div>
	</td>
);
