import { debounce } from "es-toolkit";
import { SUGGESTIONS_PAGE_SIZE } from "../../../../models/src/lib/constants/suggestion.constants";
import type {
	IDateFilter,
	IFilterItem,
} from "../../../../models/src/lib/interfaces/filter.interface";
import type { AppDispatch } from "../../../../stores/src/lib/utils";
import { buildFilterQueryString } from "./query.helpers";

interface SuggestionsSearchChangeParams {
	dispatchFunction: AppDispatch;
	searchFields: string[];
	// biome-ignore lint/suspicious/noExplicitAny: can't be bothered to type this
	reduxAction: any;
	extraDateList?: IDateFilter[];
	extraTypesLists?: IFilterItem[][];
	extraConstraintList?: IFilterItem[];
}

/**
 * `suggestionsSearchChange` is a helper function to construct a function that dispatches the search
 * actions for a particular `CelerumSuggestionsInput`. By describing the extra filters and the search fields
 * necessary, the behaviour of the particular input can be described in a declarative way, through the `SuggestionsSearchChangeParams`
 * interface, without needing to explicitly define the side-effectul actions in every component that uses this
 * input, or similar kinds of inputs.
 */

export const suggestionsSearchChange = ({
	dispatchFunction,
	searchFields,
	reduxAction,
	extraDateList,
	extraTypesLists,
	extraConstraintList,
}: SuggestionsSearchChangeParams) => {
	return debounce((searchQuery: string) => {
		const filters = buildFilterQueryString(
			searchQuery?.toString(),
			searchFields,
			extraDateList ?? undefined,
			extraTypesLists ?? undefined,
			extraConstraintList ?? undefined,
		);
		dispatchFunction(
			reduxAction({ page: 1, pageSize: SUGGESTIONS_PAGE_SIZE, filters }),
		);
	}, 500);
};
