import cls from "classnames";
import { NOT_AVAILABLE } from "../../../../../../common/models/src/lib/constants/messages.constants";
import type { IDriver } from "../../../../../../common/models/src/lib/interfaces/driver.interface";
import { CelerumTag } from "../../../../../../common/ui/src/lib/components/celerum-tag/celerum-tag.component";
import { useScrollbarDetection } from "../../../../../../common/utils/src/lib/hooks/use-scrollbar-detection.hook";
import styles from "./driver-information-card-container.module.css";

interface DriverInformationCardContainerProps {
	driverDetails: IDriver;
}

export const DriverInformationCardContainer = ({
	driverDetails,
}: DriverInformationCardContainerProps) => {
	const { containerRef, hasScrollBar } = useScrollbarDetection();

	return (
		<div
			className={cls(
				styles.container,
				hasScrollBar ? styles.scrollPadding : "",
			)}
			ref={containerRef}
		>
			<div className={styles.content}>
				<div className={styles.content__item}>
					<span className={styles.content__item__title}>First Name</span>
					<span className={styles.content__item__value}>
						{driverDetails.firstName}
					</span>
				</div>
				<div className={styles.content__item}>
					<span className={styles.content__item__title}>Last Name</span>
					<span className={styles.content__item__value}>
						{driverDetails.lastName}
					</span>
				</div>
				<div className={styles.content__item}>
					<span className={styles.content__item__title}>
						License Expiration Date
					</span>
					<span className={styles.content__item__value}>
						{new Date(driverDetails.licenceExpirationDate).toLocaleDateString()}
					</span>
				</div>
				<div className={styles.content__item}>
					<span className={styles.content__item__title}>Phone Numbers</span>
					{driverDetails.phones.length ? (
						<div className={styles.content__item__array}>
							{driverDetails.phones.map((phone) => (
								<CelerumTag
									key={phone}
									className={styles.content__item__value}
									name={phone}
								/>
							))}
						</div>
					) : (
						<span className={styles.content__item__value}>{NOT_AVAILABLE}</span>
					)}
				</div>
				<div className={styles.content__item}>
					<span className={styles.content__item__title}>Emails</span>
					{driverDetails.emails.length ? (
						<div className={styles.content__item__array}>
							{driverDetails.emails.map((email) => (
								<CelerumTag
									key={email}
									className={styles.content__item__value}
									name={email}
								/>
							))}
						</div>
					) : (
						<span className={styles.content__item__value}>{NOT_AVAILABLE}</span>
					)}
				</div>
				<div className={styles.content__item}>
					<span className={styles.content__item__title}>Notes</span>
					<span className={styles.content__item__value}>
						{driverDetails.notes || NOT_AVAILABLE}
					</span>
				</div>
			</div>
		</div>
	);
};
