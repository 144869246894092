import { SvgIcon } from "@progress/kendo-react-common";
import { pdfIcon } from "../../../../../models/src/lib/constants/icon.constants";
import { CelerumTooltip } from "../celerum-tooltip/celerum-tooltip.component";
import styles from "./celerum-document.module.css";

interface CelerumDocumentProps {
	documentName: string;
	onClick: () => void;
}

export const CelerumDocument = ({
	documentName,
	onClick,
}: CelerumDocumentProps) => (
	<div className={styles.container}>
		<div className={styles.icon}>
			<SvgIcon width={25} icon={pdfIcon} />
		</div>
		<div className={styles.info}>
			<CelerumTooltip title={documentName}>
				<p className={styles.info__name}>{documentName}</p>
			</CelerumTooltip>
			<p className={styles.info__download} onClick={onClick} onKeyUp={onClick}>
				Download
			</p>
		</div>
	</div>
);
