import type { SortDescriptor } from "@progress/kendo-data-query";
import { Icon, SvgIcon } from "@progress/kendo-react-common";
import type {
	GridColumnProps,
	GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import { upDownArrowsIcon } from "../../../../../../../models/src/lib/constants/icon.constants";
import styles from "./header-cell.module.css";

interface HeaderCellProps {
	column: GridColumnProps;
	headerProps: GridHeaderCellProps;
	sortState: SortDescriptor[];
}

export const HeaderCell = ({
	column,
	headerProps,
	sortState,
}: HeaderCellProps) => {
	const dir = sortState.find((x) => x.field === column.field)?.dir;
	const icon = dir === "asc" ? "sort-asc-sm" : "sort-desc-sm";
	return (
		<div
			style={{ cursor: column.sortable ? "pointer" : "default" }}
			onClick={headerProps.onClick}
			onKeyUp={headerProps.onClick}
		>
			<span className={styles.title}>{column.title}</span>
			{column.sortable && dir ? (
				<Icon name={icon} />
			) : (
				<SvgIcon width={16} icon={upDownArrowsIcon} />
			)}
		</div>
	);
};
