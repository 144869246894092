import { CelerumSkeleton } from "../../../celerum-skeleton/celerum-skeleton.component";
import { CelerumTag } from "../../../celerum-tag/celerum-tag.component";
import styles from "./tags-cell.module.css";

interface TagsCellProps {
	// biome-ignore lint/suspicious/noExplicitAny: can't be bothered to type this
	items: any[];
	loading: boolean;
}

export const TagsCell = ({ items, loading }: TagsCellProps) => {
	if (loading) {
		return (
			<td>
				<CelerumSkeleton />
			</td>
		);
	}

	return (
		<td>
			<div className={styles.container}>
				{items.map((item) => (
					<CelerumTag key={item} className={styles.tag} name={item.name} />
				))}
			</div>
		</td>
	);
};
