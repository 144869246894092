import cls from "classnames";
import styles from "./celerum-tag.module.css";

interface CelerumTagProps {
	name: string;
	className?: string;
}

export const CelerumTag = ({ name, className }: CelerumTagProps) => (
	<div className={cls(className, styles.container)}>
		<span className={styles.title}>{name}</span>
	</div>
);
