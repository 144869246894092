import { Checkbox } from "@progress/kendo-react-inputs";
import type { IChecklistItem } from "../../../../../common/models/src/lib/interfaces/invoice.interface";
import {
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../common/stores/src/lib/utils";
import { CelerumCard } from "../../../../../common/ui/src/lib/components/celerum-cards/celerum-cards.component";
import { CelerumLoader } from "../../../../../common/ui/src/lib/components/celerum-loader/celerum-loader.components";
import { updateInvoiceChecklistStateAction } from "../../../../data-access/src/lib/invoices.slice";
import styles from "./invoice-checklist-feature.module.css";

interface InvoiceChecklistFeatureProps {
	id: string | undefined;
	className: string | undefined;
}

export const InvoiceChecklistFeature = ({
	id,
	className,
}: InvoiceChecklistFeatureProps) => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const { loading, checklistItems, invoice } = useAppSelector((state) => ({
		loading: state.invoices.loading,
		checklistItems: state.invoices.checklistItems,
		invoice: state.jobs.job,
	}));

	const handleSelect = async (name: string, checked: boolean) => {
		const updatedChecklist = checklistItems.map((item: IChecklistItem) => {
			if (item.name === name) {
				return { ...item, checked };
			}
			return item;
		});

		await dispatchWithNotifications({
			action: updateInvoiceChecklistStateAction,
			payload: { id: Number(id), checklist: updatedChecklist },
			successMessage: "Checklist updated successfully.",
			errorMessage: "Could not update checklist.",
		});
	};

	return (
		<CelerumCard className={className}>
			<div className={styles.header}>
				<span className={styles.header__title}>
					{invoice?.customer?.checklist?.name || "Checklist"}
				</span>
			</div>
			{loading.checklist ? (
				<div className={styles.loader}>
					<CelerumLoader visible />
				</div>
			) : (
				<div className={styles.container}>
					{checklistItems.map((item: IChecklistItem) => (
						<Checkbox
							key={item.name}
							name={item.name}
							label={item.name}
							value={item.checked}
							className={styles.checkbox}
							onChange={(event) => {
								handleSelect(
									event.target.name as string,
									event.target.value as boolean,
								);
							}}
						/>
					))}
				</div>
			)}
		</CelerumCard>
	);
};
