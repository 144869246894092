import type { ComponentProps } from "react";
import {
	jobApi,
	toasted,
	useLookupCustomers,
	useMapSettings,
} from "../helpers";
import { LocationForm } from "./LocationForm";

type LocationFormProps = ComponentProps<typeof LocationForm>;

export const LocationFormWithDTO = ({
	onSubmit,
	defaultValues,
}: Pick<LocationFormProps, "onSubmit" | "defaultValues">) => {
	const lookupCustomers = useLookupCustomers();
	const defaultMapCenter = useMapSettings();
	if (!defaultMapCenter.isFetchedAfterMount) return "Loading...";

	return (
		<LocationForm
			defaultValues={{
				...defaultValues,
				latitude:
					defaultValues?.latitude ?? defaultMapCenter.data.mapCenterLatitude,
				longitude:
					defaultValues?.longitude ?? defaultMapCenter.data.mapCenterLongitude,
			}}
			lCustomers={lookupCustomers}
			onSubmit={async (data) => {
				const { id, ...rest } = data;
				const processData = async () => {
					if (id) {
						await jobApi.location.locationUpdate({ id, ...rest });
						onSubmit(data);
					} else {
						const result = await jobApi.location.locationCreate({ ...rest });
						type TheRightType = Parameters<typeof onSubmit>[0];
						onSubmit({ ...result.data, ...data } as TheRightType);
					}
				};
				toasted(processData(), id ? "Updating Location" : "Creating Location");
			}}
		/>
	);
};
