import { Reveal } from "@progress/kendo-react-animation";
import {
	ExpansionPanel,
	type ExpansionPanelActionEvent,
	ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import cls from "classnames";
import { useState } from "react";
import type { IQualification } from "../../../../../../common/models/src/lib/interfaces/qualification.interface";
import { useAppSelector } from "../../../../../../common/stores/src/lib/utils";
import { useScrollbarDetection } from "../../../../../../common/utils/src/lib/hooks/use-scrollbar-detection.hook";
import { QualificationAccordionContent } from "../qualification-accordion-content/qualification-accordion-content.component";
import { QualificationAccordionHeader } from "../qualification-accordion-header/qualification-accordion-header.component";
import styles from "./qualifications-card-container.module.css";

interface QualificationsCardContainerProps {
	setSelectedQualification: (qualification: IQualification) => void;
	handleDeleteQualification: (qualificationId: number) => void;
}

export const QualificationsCardContainer = ({
	setSelectedQualification,
	handleDeleteQualification,
}: QualificationsCardContainerProps) => {
	const { containerRef, hasScrollBar } = useScrollbarDetection();

	const { qualifications } = useAppSelector((state) => state.drivers);

	const [expanded, setExpanded] = useState<number | undefined>();

	return (
		<div
			className={cls(
				styles.container,
				hasScrollBar ? styles.scrollPadding : "",
			)}
			ref={containerRef}
		>
			{qualifications.map((qualification: IQualification, index: number) => (
				<div key={`${qualification.id}-${index}`} className={styles.accordion}>
					<ExpansionPanel
						title={
							<QualificationAccordionHeader
								qualification={qualification}
								setSelectedQualification={setSelectedQualification}
								handleDeleteQualification={handleDeleteQualification}
							/>
						}
						expanded={expanded === qualification.id}
						onAction={(event: ExpansionPanelActionEvent) => {
							setExpanded(event.expanded ? undefined : qualification.id);
						}}
						tabIndex={index}
					>
						<Reveal>
							{expanded === qualification.id && (
								<ExpansionPanelContent>
									<div onKeyDown={(e) => e.stopPropagation()}>
										<QualificationAccordionContent
											qualification={qualification}
										/>
									</div>
								</ExpansionPanelContent>
							)}
						</Reveal>
					</ExpansionPanel>
				</div>
			))}
		</div>
	);
};
