import type { IBase } from "../../../../../../../../common/models/src/lib/interfaces/base.interface";
import styles from "./selected-goods-modal-content.module.css";

import type { Dispatch, SetStateAction } from "react";

interface SelectGoodsModalContentProps {
	goods: IBase[];
	setSelectedGoods: Dispatch<SetStateAction<IBase | undefined>>;
	selectedGoods: IBase | undefined;
}

export const SelectGoodsModalContent = ({
	goods,
	selectedGoods,
	setSelectedGoods,
}: SelectGoodsModalContentProps) => (
	<div className={styles.container}>
		<div>
			{goods?.map((item) => (
				<div key={item.id}>
					<label className={styles.checkmark}>
						<span>{item.name}</span>
						<input
							type="radio"
							checked={item.id === selectedGoods?.id}
							onChange={() => setSelectedGoods(item)}
						/>
						<span className={styles.checkmark__radio} />
					</label>
				</div>
			))}
		</div>
	</div>
);
