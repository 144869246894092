import type { Dispatch, SetStateAction } from "react";
import { NOT_AVAILABLE } from "../../../../../../common/models/src/lib/constants/messages.constants";
import type {
	IGoodsForLoad,
	IGoodsResponseDto,
} from "../../../../../../common/models/src/lib/interfaces/goods.interface";
import styles from "./load-goods-modal-content.module.css";

interface LoadGoodsGridModalProps {
	selectedGoods: IGoodsResponseDto[];
	setSelectedGoodsForLoad: Dispatch<SetStateAction<IGoodsForLoad[]>>;
}

export const LoadGoodsModalContent = ({
	selectedGoods,
	setSelectedGoodsForLoad,
}: LoadGoodsGridModalProps) => {
	const onSelect = (
		id: number,
		field: "haveDeliveryOnRoute" | "havePickupOnRoute",
	) => {
		setSelectedGoodsForLoad((prevSelectedGoods) => {
			const updatedGoodsResponse = prevSelectedGoods.map((item) => {
				if (item.goodsId === id) {
					return { ...item, [field]: !item[field] };
				}
				return item;
			});
			return updatedGoodsResponse;
		});
	};

	return (
		<div className={styles.container}>
			<table className={styles.table}>
				<thead>
					<tr className={styles.table__thead}>
						<th className={styles.table__headerCell}>Goods Description</th>
						<th className={styles.table__headerCell}>Collection Location</th>
						<th className={styles.table__headerCell}>Delivery Location</th>
						<th className={styles.table__headerCell}>Customer</th>
						<th className={styles.table__headerCell}>Actions</th>
					</tr>
				</thead>
				<tbody>
					{selectedGoods.map((item: IGoodsResponseDto) => (
						<tr key={item.id} className={styles.table__row}>
							<td className={styles.table__dataCell}>
								{item?.name ?? NOT_AVAILABLE}
							</td>
							<td className={styles.table__dataCell}>
								{item?.collectionLocation?.name ?? NOT_AVAILABLE}
							</td>
							<td className={styles.table__dataCell}>
								{item?.deliveryLocation?.name ?? NOT_AVAILABLE}
							</td>
							<td className={styles.table__dataCell}>
								{item?.customer?.name ?? NOT_AVAILABLE}
							</td>
							<td>
								<div>
									<input
										type="checkbox"
										onClick={() => onSelect(item.id, "havePickupOnRoute")}
									/>
									<span>Pick-up en route</span>
								</div>
								<div>
									<input
										type="checkbox"
										onClick={() => onSelect(item.id, "haveDeliveryOnRoute")}
									/>
									<span>Deliver en route</span>
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
