import { DrawerItem } from "@progress/kendo-react-layout";
import cls from "classnames";
import type { CelerumDrawerItemProps } from "../../../../../../../models/src/lib/interfaces/celerum-drawer-item-props.interface";
import commonStyles from "../custom-drawer-item/custom-drawer-item.module.css";
import styles from "./link-drawer-item.module.css";

export const LinkDrawerItem = ({
	id,
	navbarExpanded,
	selected,
	onSelect,
	route,
	text,
}: CelerumDrawerItemProps) => {
	const computedStyle = cls(
		commonStyles.drawerItem,
		navbarExpanded ? commonStyles.expanded : "",
		selected ? styles.selectedLink : "",
	);

	return (
		<DrawerItem
			className={computedStyle}
			id={id}
			selected={selected}
			onSelect={onSelect}
			route={route}
		>
			<span style={{ whiteSpace: "pre" }} className="k-item-text">
				{`${selected ? "\u2022 " : "  "}${text}`}
			</span>
		</DrawerItem>
	);
};
