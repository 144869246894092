import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { type GoodFormObject, GoodSchema } from "../Good";
import { DFlex } from "../display/DFlex";
import {
	GenericForm,
	IDateTime,
	IMultiSelect,
	INumber,
	ISelect,
	ITextArea,
	type LoadOptionsFn,
} from "./GenericForm";

type GoodFormProps = {
	defaultValues?: Partial<GoodFormObject>;
	onSubmit: (data: GoodFormObject) => void;
	lConstraints: LoadOptionsFn;
	lLocations: LoadOptionsFn;
};
export const GoodForm = ({
	defaultValues,
	onSubmit,
	lConstraints,
	lLocations,
}: GoodFormProps) => {
	const form = useForm<GoodFormObject>({
		resolver: yupResolver(GoodSchema),
		defaultValues,
	});
	return (
		<GenericForm
			form={form}
			schema={GoodSchema}
			onSubmit={async (x) => onSubmit(x)}
		>
			<DFlex>
				<div>
					<ITextArea n="description" />
					<INumber n="revenue" />
					<INumber n="quantity" />
					<INumber n="weight" />
					<IMultiSelect n="constraintIds" l={lConstraints} />
				</div>
				<div>
					<ISelect n="collectionLocationId" l={lLocations} />
					<IDateTime n="collectionDate" />
					<ISelect n="deliveryLocationId" l={lLocations} />
					<IDateTime n="deliveryDate" />
					<ITextArea n="notes" />
				</div>
			</DFlex>
		</GenericForm>
	);
};
