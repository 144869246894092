import { Button } from "@progress/kendo-react-buttons";
import { Component, type ErrorInfo, type ReactNode } from "react";
import styles from "./custom-error-boundary.module.css";

interface CustomErrorBoundaryProps {
	children: ReactNode;
}

const refreshPageAndClearCache = () => {
	localStorage.clear();
	sessionStorage.clear();
	window.location.reload();
};

export class CustomErrorBoundary extends Component<
	CustomErrorBoundaryProps,
	{ hasError: boolean }
> {
	constructor(props: CustomErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(): { hasError: boolean } {
		return { hasError: true };
	}

	componentDidCatch(error: Error, info: ErrorInfo): void {
		console.error(error, info.componentStack);
	}

	render(): ReactNode {
		if (this.state.hasError) {
			return (
				<div className={styles.container}>
					<span className={styles.title}>
						<b>Something went wrong!</b>
					</span>
					<span className={styles.subtitle}>
						The issue may be temporary. Please go back and try again. If the
						problem persists, contact us at support@celerum.co.uk
					</span>
					<span className={styles.subtitle}>
						You can also try to{" "}
						<Button onClick={refreshPageAndClearCache}>Reload</Button>. This
						will clear your cache and refresh the page, logging you out in the
						process.
					</span>
				</div>
			);
		}

		return this.props.children;
	}
}
