import { CelerumInteractiveStatus } from "../../../celerum-interactive-status/celerum-interactive-status.component";
import { CelerumSkeleton } from "../../../celerum-skeleton/celerum-skeleton.component";
import styles from "./reactive-cell.module.css";

interface ReactiveCellProps {
	loading: boolean;
	status: number;
	id: number;
	availableStatuses: number[];
	isInteractive?: boolean;
	statusMappings?: {
		[key: number]: {
			title: string;
			className: string;
		};
	};
	handleStatusChange?: (id: number, status: number) => void;
}

export const ReactiveCell = ({
	loading,
	status,
	id,
	availableStatuses,
	isInteractive,
	statusMappings,
	handleStatusChange,
}: ReactiveCellProps) => (
	<td>
		<div className={styles.container}>
			{loading ? (
				<CelerumSkeleton />
			) : (
				<CelerumInteractiveStatus
					status={status}
					availableStatuses={availableStatuses}
					isInteractive={isInteractive}
					statusMappings={statusMappings}
					onChange={(status) => {
						handleStatusChange?.(id, status);
					}}
				/>
			)}
		</div>
	</td>
);
