import { plusIcon } from "@progress/kendo-svg-icons";
import { useState } from "react";
import { ModalSize } from "../../../../../common/models/src/lib/enums/modal.enums";
import type { IQualification } from "../../../../../common/models/src/lib/interfaces/qualification.interface";
import {
	useAppDispatchWithNotifications,
	useAppSelector,
} from "../../../../../common/stores/src/lib/utils";
import { CelerumCustomCard } from "../../../../../common/ui/src/lib/components/celerum-cards/celerum-cards.component";
import { CelerumLoader } from "../../../../../common/ui/src/lib/components/celerum-loader/celerum-loader.components";
import { CelerumModal } from "../../../../../common/ui/src/lib/components/celerum-modal/celerum-modal.component";
import { deleteQualificationAction } from "../../../../data-access/src/lib/drivers.slice";
import { QualificationForm } from "../components/qualification-form/qualification-form.component";
import { QualificationsCardContainer } from "../components/qualifications-card-container/qualifications-card-container.component";
import styles from "./qualifications-information-feature.module.css";

interface QualificationsInformationFeatureProps {
	className: string | undefined;
}

export const QualificationsInformationFeature = ({
	className,
}: QualificationsInformationFeatureProps) => {
	const dispatchWithNotifications = useAppDispatchWithNotifications();

	const { qualifications, loading } = useAppSelector((state) => state.drivers);

	const [showModal, setShowModal] = useState<boolean>(false);
	const [selectedQualification, setSelectedQualification] = useState<
		IQualification | undefined
	>(undefined);

	const handleDeleteQualification = (id: number) => {
		dispatchWithNotifications({
			action: deleteQualificationAction,
			payload: id,
			successMessage: "Qualification deleted successfully.",
			errorMessage: "Qualification could not be deleted.",
		});
	};

	const closeModal = () => {
		setShowModal(false);
		setSelectedQualification(undefined);
	};

	return (
		<>
			<CelerumCustomCard
				buttonName="Add Qualification"
				buttonSvgIcon={plusIcon}
				onClick={() => setShowModal(true)}
				title={`Qualifications (${qualifications.length})`}
				className={className}
				disabled={loading.qualifications}
			>
				{loading.qualifications ? (
					<div className={styles.verticalWrapper}>
						<CelerumLoader visible />
					</div>
				) : qualifications.length ? (
					<QualificationsCardContainer
						handleDeleteQualification={handleDeleteQualification}
						setSelectedQualification={(qualification) => {
							setSelectedQualification(qualification);
							setShowModal(true);
						}}
					/>
				) : (
					!loading.qualifications && (
						<div className={styles.verticalWrapper}>
							<div className={styles.messageWrapper}>
								<span>No qualifications found.</span>
							</div>
						</div>
					)
				)}
			</CelerumCustomCard>
			<CelerumModal
				title="Qualification"
				width={ModalSize.Medium}
				visible={showModal}
				toggleDialog={closeModal}
			>
				<QualificationForm
					onClose={closeModal}
					formState={selectedQualification}
				/>
			</CelerumModal>
		</>
	);
};
