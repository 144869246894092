import cls from "classnames";
import { CelerumCard } from "../../../../../common/ui/src/lib/components/celerum-cards/celerum-cards.component";
import { useScrollbarDetection } from "../../../../../common/utils/src/lib/hooks/use-scrollbar-detection.hook";
import { CustomerContainer } from "../components/customer-container/customer-container.component";
import { GoodsContainer } from "../components/goods-container/goods-container.component";
import { JobContainer } from "../components/job-container/job-container.component";
import { LegsContainer } from "../components/legs-container/legs-container.component";
import styles from "./invoice-information-feature.module.css";

interface InvoiceInformationFeatureProps {
	className: string | undefined;
}

export const InvoiceInformationFeature = ({
	className,
}: InvoiceInformationFeatureProps) => {
	const { containerRef, hasScrollBar } = useScrollbarDetection();

	return (
		<CelerumCard className={className}>
			<div className={styles.header}>
				<span className={styles.header__title}>
					Please make sure the following information is correct before
					generating the invoice for this job. If there are inconsistencies you
					cannot resolve, you can return the job to an operator for review.
					Sending a job for review will temporarily remove it from your job list
					view.
				</span>
			</div>
			<div
				className={cls(
					styles.container,
					hasScrollBar ? styles.scrollPadding : "",
				)}
				ref={containerRef}
			>
				<JobContainer />
				<CustomerContainer />
				<GoodsContainer />
				<LegsContainer />
			</div>
		</CelerumCard>
	);
};
