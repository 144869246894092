import { useTitle } from "react-use";
import {
	accountActivationAction,
	resendActivationEmailAction,
} from "../../../libs/authentication/data-access/src/lib/authentication.slice";
import { AccountConfirmation } from "../../../libs/authentication/feature/src/lib/account-confirmation/account-confirmation.component";
import { useAppDispatch } from "../../../libs/common/stores/src/lib/utils";
import styles from "./account-confirmation.module.css";

import { useNavigate, useSearchParams } from "react-router-dom";

export const AccountConfirmationPage = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useTitle("Account Confirmation");

	const [searchParams] = useSearchParams();
	const email = searchParams.get("email");
	const token = searchParams.get("token");

	const resendAccountConfirmationEmail = () => {
		if (email) {
			dispatch(resendActivationEmailAction(email));
		}
	};

	const handleActivateAccount = async (password: string) => {
		if (email && token && password) {
			const requestPayload = { email, token, password };

			try {
				const actionResult = await dispatch(
					accountActivationAction(requestPayload),
				);
				if (accountActivationAction.fulfilled.match(actionResult)) {
					navigate("/login");
				}
			} catch (error) {
				console.error(error);
			}
		}
	};

	return (
		<div className={styles.root}>
			<div className={styles.leftSide} />
			<div className={styles.rightSide}>
				<AccountConfirmation
					onSubmit={handleActivateAccount}
					resendEmail={resendAccountConfirmationEmail}
				/>
			</div>
		</div>
	);
};
