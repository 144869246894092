import { SvgIcon } from "@progress/kendo-react-common";
import cls from "classnames";
import {
	arrowIcon,
	deliveryIconRounded,
} from "../../../../../../common/models/src/lib/constants/icon.constants";
import { NOT_AVAILABLE } from "../../../../../../common/models/src/lib/constants/messages.constants";
import type { IJobDetails } from "../../../../../../common/models/src/lib/interfaces/job.interface";
import { CelerumAddressCard } from "../../../../../../common/ui/src/lib/components/celerum-cards/celerum-cards.component";
import { formatDate } from "../../../../../../common/utils/src/lib/helpers/date.helpers";
import { useScrollbarDetection } from "../../../../../../common/utils/src/lib/hooks/use-scrollbar-detection.hook";
import { JobInformationGrid } from "../job-information-grid/job-information-grid.component";
import styles from "./job-information-card-container.module.css";

interface JobInformationCardContainerProps {
	jobDetails: IJobDetails;
}

export const JobInformationCardContainer = ({
	jobDetails,
}: JobInformationCardContainerProps) => {
	const { startLocation, startDate, endLocation, endDate, notes } = jobDetails;

	const { containerRef, hasScrollBar } = useScrollbarDetection();

	return (
		<div
			className={cls(
				styles.container,
				hasScrollBar ? styles.scrollPadding : styles.noScroll,
			)}
			ref={containerRef}
		>
			<JobInformationGrid jobDetails={jobDetails} />
			<div className={styles.secondRow}>
				<div className={styles.paragraphDescriptionContainer}>
					<p className={styles.paragraphDescription}>
						{notes ? notes : "No notes added yet!"}
					</p>
				</div>
				<div className={styles.addressCardContainer}>
					<CelerumAddressCard
						title="Start Location"
						name={startLocation?.name ?? NOT_AVAILABLE}
						address={startLocation?.address ?? NOT_AVAILABLE}
						date={startDate ? formatDate(new Date(startDate)) : NOT_AVAILABLE}
					/>
					<SvgIcon width={50} icon={arrowIcon} />
					<CelerumAddressCard
						title="End Location"
						name={endLocation?.name ?? NOT_AVAILABLE}
						address={endLocation?.address ?? NOT_AVAILABLE}
						date={endDate ? formatDate(new Date(endDate)) : NOT_AVAILABLE}
						icon={deliveryIconRounded}
					/>
				</div>
			</div>
		</div>
	);
};
