import { CelerumSkeleton } from "../../../celerum-skeleton/celerum-skeleton.component";
import styles from "./reverse-ellipsis-cell.module.css";

interface ReverseEllipsisCellProps {
	// biome-ignore lint/suspicious/noExplicitAny: can't be bothered to type this
	item: any;
	loading: boolean;
}

export const ReverseEllipsisCell = ({
	item,
	loading,
}: ReverseEllipsisCellProps) => {
	if (loading) {
		return (
			<td>
				<CelerumSkeleton />
			</td>
		);
	}

	return (
		<td>
			<div className={styles.container}>{item}</div>
		</td>
	);
};
