import { useAppSelector } from "../../../../../common/stores/src/lib/utils";
import { CelerumCard } from "../../../../../common/ui/src/lib/components/celerum-cards/celerum-cards.component";
import { LoginForm } from "./login-form/login-form.component";
import styles from "./login.module.css";

import { GridLayout } from "@progress/kendo-react-layout";

export const Login = () => {
	const { isMobile } = useAppSelector((state) => state.userInterface);

	const renderHeader = () => (
		<div className={styles.header}>
			<h1 className={styles.header__title}>Log into Celerum TLS</h1>
			<a
				href="https://gcl.driver.celerum.online/login"
				className={styles.header__link}
			>
				Login as a Driver
			</a>
			<h3 className={styles.header__description}>
				Please enter your details below to log in.
			</h3>
		</div>
	);

	return isMobile ? (
		<div className={styles.mobile}>
			{renderHeader()}
			<LoginForm />
		</div>
	) : (
		<GridLayout
			align={{
				horizontal: "center",
			}}
		>
			<CelerumCard className={styles.card}>
				{renderHeader()}
				<LoginForm />
			</CelerumCard>
		</GridLayout>
	);
};
