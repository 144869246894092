import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTitle } from "react-use";
import { DGrid } from "../../display/DGrid";
import { jobApi, toasted } from "../../helpers";
import { LoadDetailsTitle } from "./LoadDetailsTitle";

export const LoadDetailsPage2 = () => {
	const params = useParams();
	const loadId = Number(params.id);
	const response = useQuery({
		queryKey: ["load-details", loadId],
		queryFn: () => jobApi.bff.bffLoadDetailsDetail(loadId),
	});
	const data = useMemo(() => response.data?.data, [response.data?.data]);
	const navigate = useNavigate();
	useTitle(
		data ? `Load ${data.id} - ${data.uniqueId}` : "Load details, loading...",
	);
	if (!data) return <div>Loading...</div>;
	return (
		<DGrid rows="50px 1fr">
			<LoadDetailsTitle
				data={data}
				onGoBackClick={() => navigate("/loads2")}
				onActionClick={(x) => {
					switch (x) {
						case "Pause":
							// TODO: Why is this cancelling? It's actually not possible to pause so this action should not exist
							toasted(
								jobApi.load
									.loadChangeStatusPauseCreate(loadId)
									.then(() => response.refetch()),
								"Pausing load",
							);
							break;
						case "Delete":
							// TODO: And why is this archiving?
							toasted(
								jobApi.load
									.loadChangeStatusCancelCreate(loadId)
									.then(() => navigate("/loads2")),
								"Deleting load",
							);
							break;
					}
				}}
			/>
		</DGrid>
	);
};
