import styles from "./celerum-suffix.module.css";

interface SuffixComponentProps {
	value: string;
}

export const SuffixComponent = (fieldRenderProps: SuffixComponentProps) => {
	const { value } = fieldRenderProps;

	return (
		<span className={styles.iconContainer}>
			<span className={`${styles.icon}`}>{value}</span>
		</span>
	);
};
