import styles from "./checkbox-cell.module.css";

interface CheckboxCellProps {
	itemId: number;
	selectedItemsIds?: Set<number> | undefined;
	canBeChecked?: boolean;
	setSelectedItemsIds?: (items: Set<number>) => void;
}

export const CheckboxCell = ({
	itemId,
	selectedItemsIds,
	canBeChecked = false,
	setSelectedItemsIds,
}: CheckboxCellProps) => {
	const handleItemSelection = () => {
		if (!itemId || !selectedItemsIds || !setSelectedItemsIds) {
			return;
		}

		const nextSet = new Set(selectedItemsIds);

		if (selectedItemsIds.has(itemId)) {
			nextSet.delete(itemId);
		} else {
			nextSet.add(itemId);
		}

		setSelectedItemsIds(nextSet);
	};

	return (
		<td>
			<div className={styles.container}>
				<input
					disabled={canBeChecked}
					className={styles.checkbox}
					type="checkbox"
					checked={selectedItemsIds?.has(itemId)}
					onChange={handleItemSelection}
				/>
			</div>
		</td>
	);
};
