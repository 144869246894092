import { useEffect } from "react";
import { useTitle } from "react-use";
import { clearCurrentBusinessUnitAction } from "../../../libs/authentication/data-access/src/lib/authentication.slice";
import { BusinessUnitSelection } from "../../../libs/authentication/feature/src/lib/business-unit-selection/business-unit-selection.component";
import { useAppDispatch } from "../../../libs/common/stores/src/lib/utils";
import styles from "./business-unit-selection.module.css";

interface BusinessUnitSelectionPageProps {
	logoutHandler: () => void;
}

export const BusinessUnitSelectionPage = ({
	logoutHandler,
}: BusinessUnitSelectionPageProps) => {
	const dispatch = useAppDispatch();

	useTitle("Business Unit Selection");

	useEffect(() => {
		dispatch(clearCurrentBusinessUnitAction());
	}, [dispatch]);

	return (
		<div className={styles.root}>
			<div className={styles.leftSide} />
			<div className={styles.rightSide}>
				<BusinessUnitSelection logoutHandler={logoutHandler} />
			</div>
		</div>
	);
};
