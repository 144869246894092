import { GridLayout } from "@progress/kendo-react-layout";
import cls from "classnames";
import type { ComponentProps } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../common/stores/src/lib/utils";
import { CelerumButton } from "../../../../../common/ui/src/lib/components/celerum-buttons/celerum-buttons.component";
import { CelerumCard } from "../../../../../common/ui/src/lib/components/celerum-cards/celerum-cards.component";
import { ResetPasswordForm } from "./components/reset-password-form";
import styles from "./reset-password.module.css";

interface ResetPasswordProps {
	onSubmit: (password: string) => void;
	isPasswordReset: boolean;
}
type ResetPasswordFormProps = ComponentProps<typeof ResetPasswordForm>;
export const ResetPassword = ({
	onSubmit,
	isPasswordReset,
}: ResetPasswordProps) => {
	const { isMobile } = useAppSelector((state) => state.userInterface);

	const handleSubmit: ResetPasswordFormProps["handleSubmit"] = (dataItem) => {
		onSubmit(dataItem.password);
	};

	const navigate = useNavigate();

	const renderDescription = () => {
		return isPasswordReset ? (
			<h3 className={styles.description}>
				Your password has been successfully changed.
			</h3>
		) : (
			<h3 className={styles.description}>
				To protect your account, make sure you choose a strong password.
			</h3>
		);
	};

	const renderBody = () => {
		return isPasswordReset ? (
			<CelerumButton
				title="Go to Log in"
				type="button"
				onClick={() => navigate("/login")}
			/>
		) : (
			<ResetPasswordForm styles={styles} handleSubmit={handleSubmit} />
		);
	};

	return isMobile ? (
		<div className={styles.mobile}>
			<div className={styles.titleContainer}>
				<h1 className={styles.title}>Reset Password</h1>
				{renderDescription()}
			</div>
			{renderBody()}
		</div>
	) : (
		<GridLayout align={{ horizontal: "center" }}>
			<CelerumCard
				className={cls(
					styles.card,
					isPasswordReset ? styles.card_small : styles.card__default,
				)}
			>
				<div className={styles.titleContainer}>
					<h1 className={styles.title}>Reset Password</h1>
					{renderDescription()}
				</div>
				{renderBody()}
			</CelerumCard>
		</GridLayout>
	);
};
