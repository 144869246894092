import { SvgIcon } from "@progress/kendo-react-common";
import { DrawerItem } from "@progress/kendo-react-layout";
import cls from "classnames";
import type { CelerumDrawerItemProps } from "../../../../../../../models/src/lib/interfaces/celerum-drawer-item-props.interface";
import commonStyles from "../custom-drawer-item/custom-drawer-item.module.css";
import styles from "./category-drawer-item.module.css";

export const CategoryDrawerItem = ({
	linksVisible,
	navbarExpanded,
	selected,
	svgIcon,
	text,
	...others
}: CelerumDrawerItemProps) => {
	const arrowDir = linksVisible
		? "k-i-arrow-chevron-up"
		: "k-i-arrow-chevron-down";

	const computedStyle = cls(
		commonStyles.drawerItem,
		commonStyles.mainItem,
		selected ? styles.selectedCategory : "",
		navbarExpanded ? commonStyles.expanded : "",
	);

	return !navbarExpanded ? (
		<DrawerItem selected={selected} className={computedStyle} {...others}>
			<SvgIcon
				width={25}
				className={commonStyles.svgIconStyle}
				icon={svgIcon}
			/>
		</DrawerItem>
	) : (
		<DrawerItem selected={selected} className={computedStyle} {...others}>
			<SvgIcon
				width={20}
				className={commonStyles.svgIconStyle}
				icon={svgIcon}
			/>
			<div className={styles.categorySelection}>
				<span className="k-item-text">{text}</span>
				<span className={`k-icon k-font-icon ${arrowDir}`} />
			</div>
		</DrawerItem>
	);
};
